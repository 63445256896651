import { useQueryStepsForm } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import arrayMutators from 'final-form-arrays';
import {
  ColumnType,
  GroupedColumn,
  LimitCondition,
  LimitStep,
  QueryOperationType,
} from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { useSingleQueryStep } from '../../../contexts/SingleQueryStepContext';
import { InvalidQueryStep } from '../InvalidQueryStep';
import { SectionHeader, Subtext } from '../styled';
import { LimitConditions } from './LimitConditions';
import { FormValueLimitCondition, FormValues, LimitType } from './types';
import { getInitialLimitCondition } from './utilities';

const Container = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

const limitTypeOptions: SelectableOption<LimitType>[] = [
  { label: 'Maximum', value: LimitType.max },
  { label: 'Minimum', value: LimitType.min },
  { label: 'Range', value: LimitType.range },
];

const buildLimitStep = (
  queryStep: LimitStep,
  values: FormValues,
): LimitStep => {
  const limitConditions: LimitCondition[] = values.limitConditions.map(
    (condition) => {
      const result: LimitCondition = {
        groupByFilters: condition.groupByFilters || [],
      };

      if (condition.max != null) {
        result.max =
          condition.units != null
            ? { value: Number(condition.max), unit: condition.units }
            : Number(condition.max);
      }

      if (condition.min != null) {
        result.min =
          condition.units != null
            ? { value: Number(condition.min), unit: condition.units }
            : Number(condition.min);
      }
      return result;
    },
  );

  return {
    ...queryStep,
    operationType: QueryOperationType.LIMIT,
    operation: {
      columnName: values.columnName,
      limitConditions,
      name: values.name,
    },
  };
};

const buildFormValues = (
  queryStep?: LimitStep,
  groupedColumns?: GroupedColumn[],
  areViewLimitFiltersEnabled = false,
): DeepPartial<FormValues> => {
  if (!queryStep || !queryStep.operation) {
    return {
      limitConditions: areViewLimitFiltersEnabled
        ? [{ groupByFilters: [] }]
        : [getInitialLimitCondition(groupedColumns)],
      limitType: LimitType.max,
    };
  }

  const limitConditions = queryStep.operation?.limitConditions?.map(
    (condition) => {
      const result: FormValueLimitCondition = {
        groupByFilters: condition.groupByFilters,
      };
      if (condition.max != null) {
        if (typeof condition.max === 'number') {
          result.max = `${condition.max}`;
        } else {
          result.max = `${condition.max.value}`;
          result.units = condition.max.unit;
        }
      }
      if (condition.min != null) {
        if (typeof condition.min === 'number') {
          result.min = `${condition.min}`;
        } else {
          result.min = `${condition.min.value}`;
          result.units = condition.min.unit;
        }
      }
      return result;
    },
  );

  let limitType: LimitType;

  const hasMax = limitConditions[0]?.max != null;
  const hasMin = limitConditions[0]?.min != null;

  if (hasMax) {
    if (hasMin) {
      limitType = LimitType.range;
    } else {
      limitType = LimitType.max;
    }
  } else {
    limitType = LimitType.min;
  }

  return {
    columnName: queryStep?.operation?.columnName,
    limitConditions,
    limitType,
    name: queryStep?.operation?.name,
  };
};

export function LimitQueryStep() {
  const { areViewLimitFiltersEnabled } = useFeatureFlags();
  const {
    availableColumns,
    dateColumn,
    groupedColumns,
    index,
    isLastStep,
    queryStep,
  } = useSingleQueryStep();
  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = useQueryStepsForm();

  const limitStep = queryStep as LimitStep;

  const handleOnSubmit = useCallback(
    (values: FormValues) =>
      onQueryStepSubmit(buildLimitStep(limitStep, values), index),
    [index, limitStep, onQueryStepSubmit],
  );

  const columnOptions = useMemo(
    () =>
      availableColumns
        .filter((col) => col.columnType === ColumnType.NUMBER)
        .map((column) => ({
          label: column.columnLabel,
          value: column.columnName,
        }))
        .sort((op1, op2) => op1.label.localeCompare(op2.label)),
    [availableColumns],
  );

  const initialValue = useMemo(
    () =>
      buildFormValues(limitStep, groupedColumns, areViewLimitFiltersEnabled),
    [areViewLimitFiltersEnabled, groupedColumns, limitStep],
  );

  if (!dateColumn || columnOptions.length === 0) {
    const errorMessage = !dateColumn
      ? 'There is no date column to set a limit on.'
      : 'There are no numeric columns to set a limit on.';

    return <InvalidQueryStep errorMessage={errorMessage} />;
  }

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      initialValues={initialValue}
      mutators={{ ...arrayMutators }}
      onSubmit={handleOnSubmit}
      subscription={{ pristine: true, values: true }}
    >
      {({ handleSubmit, pristine }) => {
        registerHandleSubmitForStep(handleSubmit, index);
        setQueryStepsArePristine(pristine);
        return (
          <Container>
            <Row>
              <TextField
                disabled={!isLastStep}
                fullWidth
                label="Limit Name"
                name="name"
                placeholder="Name your limit"
                required
                validate={isRequired}
              />
              <SelectField
                disabled={!isLastStep}
                label="Limit Type"
                name="limitType"
                options={limitTypeOptions}
                required
                validate={isRequired}
              />
              <SelectField
                label="Column Name"
                name="columnName"
                options={columnOptions}
                placeholder="Input column"
                required
                validate={isRequired}
              />
            </Row>

            <SectionHeader>Limit Value</SectionHeader>
            {areViewLimitFiltersEnabled && (
              <Subtext>
                Define a single limit value for all rows or choose a set of
                columns to filter by and add conditional limit values.
              </Subtext>
            )}
            <LimitConditions
              initialLimitConditions={initialValue.limitConditions}
            />
          </Container>
        );
      }}
    </Form>
  );
}
