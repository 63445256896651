import { SelectColumnOptionField } from '@monorepo/logs/src/components/QuerySteps/LimitQueryStep/SelectColumnOptionField';
import { useQuerySteps } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { EditResourceFieldValue } from '@monorepo/shared/componentsV2/fieldDataType/values/EditFieldValueFactory/EditResourceFieldValue';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useLogInfo } from '@monorepo/shared/hooks/logs/useLogInfo';
import { isRequired } from '@monorepo/shared/utils/validators';
import {
  ColumnType,
  FieldDataType,
  FieldResponse,
  GroupByFilter,
  ResourceFieldResponse,
} from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';

const StyledEditResourceFieldValue = styled(EditResourceFieldValue)`
  width: 100%;
`;

const isResourceField = (
  x: FieldResponse | undefined,
): x is ResourceFieldResponse => x != null && x.type === FieldDataType.RESOURCE;

interface LimitFilterFieldsProps {
  hideLabels?: boolean;
  name: string;
}

export function LimitFilterFields({
  hideLabels = false,
  name,
}: LimitFilterFieldsProps) {
  const { areViewLimitFiltersEnabled } = useFeatureFlags();
  const { originId, organizationId, projectId } = useQuerySteps();
  const { availableColumns, groupedColumns } = useSingleQueryStep();
  const hasGroupedColumns = !!groupedColumns.length;

  // todo@areViewLimitFiltersEnabled remove once limit filters are released
  const { logInfo, isLoading } = useLogInfo({
    config: { enabled: areViewLimitFiltersEnabled ? false : hasGroupedColumns },
    logId: originId,
    organizationId,
  });

  // todo@areViewLimitFiltersEnabled remove once limit filters are released
  const getResourceField = useCallback(
    (fieldId: string | undefined) => {
      if (fieldId == null) {
        return undefined;
      }
      const resourceField = logInfo?.log.fields.find(
        (field) => field.id === fieldId,
      );
      if (isResourceField(resourceField)) {
        return resourceField;
      }
      return undefined;
    },
    [logInfo],
  );

  return isLoading ? (
    <Loading />
  ) : (
    <FieldArray<GroupByFilter, never> name={`${name}.groupByFilters`}>
      {({ fields: limitFilters }) => (
        <>
          {limitFilters.map((limitFilterName, idx) => {
            const limitFilter = limitFilters.value[idx];
            const column = availableColumns.find(
              (col) => col.columnName === limitFilter?.columnName,
            );
            if (!column) {
              throw new Error('Column not found');
            }

            const commonFieldProps = {
              fullWidth: true,
              isLabelHidden: hideLabels,
              key: limitFilterName,
              label: `${idx === 0 ? 'Where' : 'and'} ${column.columnLabel} is`,
              name: `${limitFilterName}.value`,
              required: true,
              validate: isRequired,
            };

            if (
              !areViewLimitFiltersEnabled &&
              column.columnType === ColumnType.FOREIGN_ID
            ) {
              const resourceField = getResourceField(column.originId);
              if (!resourceField) {
                return <Loading />;
              }
              return (
                <StyledEditResourceFieldValue
                  field={resourceField}
                  fullWidth
                  isLabelHidden={hideLabels}
                  isRequired
                  key={limitFilterName}
                  label={`${idx === 0 ? 'Where' : 'and'} ${
                    column.columnLabel
                  } is`}
                  name={`${limitFilterName}.value`}
                  organizationId={organizationId}
                  projectId={projectId}
                />
              );
            }

            if (
              areViewLimitFiltersEnabled &&
              column.columnType === ColumnType.FOREIGN_ID
            ) {
              return (
                <SelectColumnOptionField
                  {...commonFieldProps}
                  column={column}
                  organizationId={organizationId}
                  projectId={projectId}
                  queryOriginId={originId}
                />
              );
            }

            return (
              <TextField {...commonFieldProps} placeholder="Enter a value" />
            );
          })}
        </>
      )}
    </FieldArray>
  );
}
