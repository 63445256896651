import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { AggregationMethod, ColumnType } from 'mapistry-shared';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-final-form';
import styled from 'styled-components';
import { aggregationMethodLabels } from '../consts';

const DeleteButton = styled(IconButton)<{ $labelIsShowing: boolean }>`
  min-width: 2rem;
  margin-bottom: ${({ $labelIsShowing }) =>
    $labelIsShowing
      ? '0.2rem'
      : '1.3rem'}; /* lines this up vertically with the input elements to its left */

  font-size: 0.75rem;
`;

function getSupportedMethods(columnType?: ColumnType) {
  return columnType && columnType !== ColumnType.NUMBER
    ? [AggregationMethod.COUNT, AggregationMethod.COUNT_DISTINCT]
    : [
        AggregationMethod.SUM,
        AggregationMethod.AVERAGE,
        AggregationMethod.COUNT,
        AggregationMethod.COUNT_DISTINCT,
        AggregationMethod.MAX,
        AggregationMethod.MIN,
      ];
}

export function AggregationColumnDefinition({
  formFieldName,
  onDelete,
  showLabel,
}: {
  formFieldName: string;
  onDelete?: () => void;
  showLabel: boolean;
}) {
  const { availableColumns, isLastStep } = useSingleQueryStep();
  const { change, getFieldState } = useForm();
  const methodFieldState = getFieldState(`${formFieldName}.method`);
  const columnNameFieldState = getFieldState(`${formFieldName}.columnName`);
  const column = useMemo(
    () =>
      availableColumns.find(
        (c) => c.columnName === columnNameFieldState?.value,
      ),
    [availableColumns, columnNameFieldState?.value],
  );

  const columnOptions = useMemo(
    () =>
      availableColumns
        .filter(
          (c) =>
            methodFieldState?.value === AggregationMethod.COUNT_DISTINCT ||
            c.columnType === ColumnType.NUMBER,
        )
        .map((c) => ({
          label: c.columnLabel,
          value: c.columnName,
        }))
        .sort((op1, op2) => op1.label.localeCompare(op2.label)),

    [availableColumns, methodFieldState?.value],
  );

  const methodOptions = useMemo(
    () =>
      getSupportedMethods(column?.columnType).map((method) => ({
        label: aggregationMethodLabels[method],
        value: method,
      })),
    [column?.columnType],
  );
  const isRowCount = methodFieldState?.value === AggregationMethod.COUNT;
  useEffect(() => {
    isRowCount && change(`${formFieldName}.columnName`, '');
  }, [change, isRowCount, formFieldName]);
  return (
    <>
      <TextField
        disabled={!isLastStep}
        fullWidth
        isLabelHidden={!showLabel}
        label="Aggregation Name"
        name={`${formFieldName}.alias`}
        placeholder="Name your new column"
        required
        validate={isRequired}
      />
      <SelectField
        disabled={!isLastStep}
        fullWidth
        isLabelHidden={!showLabel}
        label="Function"
        name={`${formFieldName}.method`}
        options={methodOptions}
        required
        validate={isRequired}
      />
      {!isRowCount && (
        <SelectField
          disabled={!isLastStep}
          fullWidth
          isLabelHidden={!showLabel}
          label="Column"
          name={`${formFieldName}.columnName`}
          options={columnOptions}
          placeholder="Column to aggregate"
          required
          validate={isRequired}
        />
      )}
      {isLastStep && onDelete ? (
        <DeleteButton onClick={onDelete} $labelIsShowing={showLabel}>
          <TrashIcon />
          <VisuallyHidden>Delete aggregation</VisuallyHidden>
        </DeleteButton>
      ) : (
        <div />
      )}
    </>
  );
}
