import { SelectOption } from '@monorepo/shared/componentsV2/Select';
import {
  StyledFormGroup,
  StyledSelect,
} from '@monorepo/shared/componentsV2/styled/form';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import { isRequired } from '@monorepo/shared/utils/validators';
import TrashIcon from '@svg/trash.svg';
import { DatasetPropertyType, DatasetResponse } from 'mapistry-shared';
import React, { useEffect, useMemo } from 'react';
import { useField } from 'react-final-form';
import { StyledEqualsText, StyledStepDeleteButton } from './styled';

const JOIN_CONDITION_DISALLOWED_PROPERTY_TYPES = [
  // don't let users add a condition on project, while we make all joins project specific under the hood either way
  DatasetPropertyType.PROJECT,
  // joining record properties is illogical
  DatasetPropertyType.RECORD,
];

function ConditionPicker({
  dataset,
  disabled,
  fieldName,
  label,
  shouldLabelShow,
}: {
  dataset?: DatasetResponse;
  disabled?: boolean;
  fieldName: string;
  label: string;
  shouldLabelShow: boolean;
}) {
  const filteredSchemaProperties = useMemo(
    () =>
      (dataset?.schemaProperties || []).filter(
        (schemaProperty) =>
          !JOIN_CONDITION_DISALLOWED_PROPERTY_TYPES.includes(
            schemaProperty.type,
          ),
      ),
    [dataset?.schemaProperties],
  );

  const options = useMemo<SelectOption[]>(
    () =>
      filteredSchemaProperties
        .map((property) => ({ label: property.fullName, value: property.id }))
        .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label)),
    [filteredSchemaProperties],
  );

  const isDisabled = !dataset || disabled;

  return (
    <StyledSelect
      isLabelHidden={!shouldLabelShow}
      label={label}
      options={options}
      name={fieldName}
      required
      disabled={isDisabled}
      validate={isRequired}
    />
  );
}

interface StepConditionProps {
  disabled?: boolean; // todo@workflows: rethink when allowing editing join steps
  formFieldName: string;
  leftDataset?: DatasetResponse;
  rightDataset?: DatasetResponse;
  shouldLabelShow: boolean;
  canDelete: boolean;
  onDelete: () => void;
}

export function StepCondition({
  disabled,
  formFieldName,
  leftDataset,
  rightDataset,
  shouldLabelShow,
  canDelete,
  onDelete,
}: StepConditionProps) {
  const rightConditionFieldName = `${formFieldName}.rightPropertyId`;
  const { input: rightConditionInput } = useField<string>(
    rightConditionFieldName,
  );

  const prevRightDatasetId = usePrevious(rightDataset?.id);

  useEffect(() => {
    if (prevRightDatasetId && rightConditionInput.value) {
      rightConditionInput.onChange(undefined);
    }
    // clearing out the right condition when the right dataset changes
    // if I include `input` in the dependency array, it reruns too often
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightDataset?.id]);

  return (
    <StyledFormGroup row>
      <ConditionPicker
        shouldLabelShow={shouldLabelShow}
        label="Column 1"
        dataset={leftDataset}
        disabled={disabled}
        fieldName={`${formFieldName}.leftPropertyId`}
      />

      <StyledEqualsText $shouldLabelShow={shouldLabelShow}>
        equals
      </StyledEqualsText>

      <ConditionPicker
        shouldLabelShow={shouldLabelShow}
        label="Column 2"
        dataset={rightDataset}
        disabled={disabled}
        fieldName={rightConditionFieldName}
      />

      {canDelete && (
        <StyledStepDeleteButton
          onClick={onDelete}
          $shouldLabelShow={shouldLabelShow}
          disabled={disabled}
        >
          <TrashIcon />
          <VisuallyHidden>Delete condition</VisuallyHidden>
        </StyledStepDeleteButton>
      )}
    </StyledFormGroup>
  );
}
