import { ActionMenu } from '@monorepo/shared/componentsV2/menus/ActionMenu';
import { WorkflowStepResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { useToast } from '../../../../shared/contexts';
import { useWorkflowStepDelete } from '../../hooks/useWorkflowStepDelete';
import { getStepDisplayInfo } from './stepUtils';

interface WorkflowStepActionsProps {
  onDeleteSuccess?: () => void;
  organizationId: string;
  step: WorkflowStepResponse;
  openModalForExistingStep: (step: WorkflowStepResponse) => void;
}

export function WorkflowStepActions({
  onDeleteSuccess,
  organizationId,
  step,
  openModalForExistingStep,
}: WorkflowStepActionsProps) {
  const { title } = getStepDisplayInfo(step);

  const { showUserFriendlyErrorToast, success: showSuccess } = useToast();

  const [deleteStep] = useWorkflowStepDelete({
    config: {
      onError: (err) =>
        showUserFriendlyErrorToast(err, `Unable to delete ${title} step.`),
      onSuccess: () => {
        onDeleteSuccess && onDeleteSuccess();
        showSuccess(`${title} step has been deleted.`);
      },
    },
  });

  const actionMenuOptions = useMemo(
    () => [
      {
        // todo@workflows: rename to edit once we allow editing steps again
        displayName: 'View',
        onClick: () => openModalForExistingStep(step),
      },
      {
        displayName: 'Delete',
        onClick: () =>
          deleteStep({
            organizationId,
            workflowId: step.workflowId,
            stepId: step.id,
          }),
      },
    ],
    [deleteStep, openModalForExistingStep, organizationId, step],
  );

  return (
    <ActionMenu
      altText={`Action menu for ${title} Step`}
      options={actionMenuOptions}
    />
  );
}
