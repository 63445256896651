import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { MenuButton } from '@monorepo/shared/componentsV2/menus/MenuButton';
import FormulaIcon from '@svg/formula.svg';
import JoinIcon from '@svg/join.svg';
import { WorkflowStepOperationType } from 'mapistry-shared';
import React, { useMemo } from 'react';

interface AddStepButtonProps {
  onAddNew: (stepType: WorkflowStepOperationType) => void;
  buttonRef?: React.Ref<HTMLButtonElement>;
}

export function AddStepButton({ onAddNew, buttonRef }: AddStepButtonProps) {
  const menuOptions = useMemo(
    () => [
      {
        displayName: 'Formula',
        icon: <FormulaIcon />,
        onClick: () => onAddNew(WorkflowStepOperationType.FORMULA),
      },
      {
        displayName: 'Connect',
        icon: <JoinIcon />,
        onClick: () => onAddNew(WorkflowStepOperationType.JOIN),
      },
    ],
    [onAddNew],
  );

  return (
    <MenuButton
      icon={<AddCircleOutlineIcon />}
      label="Add a step"
      options={menuOptions}
      popperPlacement="bottom-start"
      variant="outlined"
      buttonRef={buttonRef}
    />
  );
}
