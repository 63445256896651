import { useSingleQueryStep } from '@monorepo/logs/src/contexts/SingleQueryStepContext';
import { Button } from '@monorepo/shared/componentsV2/Button';
import AddIcon from '@svg/add.svg';
import { GroupByFilter, MathUnit } from 'mapistry-shared';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { LimitCondition } from './LimitCondition';
import { LimitFilterPicker } from './LimitFilterPicker';
import { FormValueLimitCondition, LimitType } from './types';
import { getInitialLimitCondition as getInitialLimitConditionObsolete } from './utilities';

export const Container = styled.div<{ $withMaxWidth: boolean }>`
  max-width: ${({ $withMaxWidth }) => ($withMaxWidth ? '30rem' : '')};
  padding: 1rem 0.8rem 0.6rem;
  border: 1px solid ${({ theme }) => theme.colors.grayddd};
  border-radius: 0.25rem;
`;

const AddButton = styled(Button)`
  width: fit-content;
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
  align-items: end;
`;

function isArrayOfStrings(list: (string | undefined)[]): list is string[] {
  return list.every((el) => el != null);
}

function getInitialLimitFilterColumnNames(
  existingLimitConditions?: DeepPartial<FormValueLimitCondition>[],
): string[] {
  const limitFilters = existingLimitConditions?.[0]?.groupByFilters || [];
  const limitFilterColumnNames = limitFilters.map(
    ({ columnName }) => columnName,
  );

  // Should never happen, but for TS form value is deep-partial, and it seems that
  // the type can't be defined more specific because it needs to play along with `final-form-arrays` types.
  if (!isArrayOfStrings(limitFilterColumnNames)) {
    throw new Error(
      'Limit step form: some of limit conditions have empty column names for filters',
    );
  }
  return limitFilterColumnNames;
}

function getDefaultLimitFilter(columnName: string): GroupByFilter {
  return { columnName, value: '' };
}

function doLimitFiltersNeedToChange(
  currentLimitFilters: GroupByFilter[],
  limitFilterColumnNames: string[],
) {
  if (currentLimitFilters.length !== limitFilterColumnNames.length) {
    return true;
  }
  const columnNamesFromBoth = new Set([
    ...limitFilterColumnNames,
    ...currentLimitFilters.map(({ columnName }) => columnName),
  ]);
  return columnNamesFromBoth.size !== currentLimitFilters.length;
}

function getUpdatedLimitFilters(
  currentLimitFilters: GroupByFilter[],
  limitFilterColumnNames: string[],
) {
  const currentFiltersByColumnName = currentLimitFilters.reduce(
    (columnNameToFilterMap, currentFilter) => ({
      ...columnNameToFilterMap,
      [currentFilter.columnName]: currentFilter,
    }),
    {} as Record<string, GroupByFilter>,
  );
  return limitFilterColumnNames.map(
    (columnName) =>
      currentFiltersByColumnName[columnName] ||
      getDefaultLimitFilter(columnName),
  );
}

function getUpdatedLimitConditions(
  limitConditions: FormValueLimitCondition[],
  limitFilterColumnNames: string[],
) {
  return limitConditions.map((condition) => ({
    ...condition,
    groupByFilters: getUpdatedLimitFilters(
      condition.groupByFilters || [],
      limitFilterColumnNames,
    ),
  }));
}

type LimitConditionsProps = {
  initialLimitConditions?: DeepPartial<FormValueLimitCondition>[];
};

export function LimitConditions({
  initialLimitConditions,
}: LimitConditionsProps) {
  const { areViewLimitFiltersEnabled } = useFeatureFlags();
  const { availableColumns, groupedColumns } = useSingleQueryStep();
  const { batch, change, getFieldState, getState } = useForm();

  const getUnitsForColumn = useCallback(
    (columnName?: string) => {
      if (!columnName) {
        return undefined;
      }
      const column = availableColumns.find(
        (col) => col.columnName === columnName,
      );

      return column?.units;
    },
    [availableColumns],
  );

  const { values } = getState();
  const units = getUnitsForColumn(values.columnName);
  const limitType = getFieldState('limitType')?.value;

  const [limitFilterColumnNames, setLimitFilterColumnNames] = useState(
    getInitialLimitFilterColumnNames(initialLimitConditions),
  );

  const hasLimitFilterColumns = areViewLimitFiltersEnabled
    ? !!limitFilterColumnNames.length
    : !!groupedColumns.length;

  const handleLimitFilterChange = useCallback(
    (columnName: string, isChecked: boolean) => {
      setLimitFilterColumnNames((currentState) => {
        if (isChecked) {
          // Inserting a new column in the same order as it appears in available columns
          return availableColumns
            .filter(
              (c) =>
                currentState.includes(c.columnName) ||
                c.columnName === columnName,
            )
            .map((c) => c.columnName);
        }
        return currentState.filter((cn) => cn !== columnName);
      });
    },
    [availableColumns, setLimitFilterColumnNames],
  );

  // Keeping FF field value in sync with selected limit filter columns
  useEffect(() => {
    const limitConditions = getFieldState('limitConditions');
    const limitFilters = limitConditions?.value[0]?.groupByFilters || [];
    if (doLimitFiltersNeedToChange(limitFilters, limitFilterColumnNames)) {
      const newValues = getUpdatedLimitConditions(
        limitConditions?.value,
        limitFilterColumnNames,
      );
      limitConditions?.change(newValues);
    }
  }, [getFieldState, limitFilterColumnNames]);

  const getDefaultLimitCondition = useCallback(
    () => ({
      groupByFilters: limitFilterColumnNames.map(getDefaultLimitFilter),
    }),
    [limitFilterColumnNames],
  );

  return (
    <>
      <FieldArray<FormValueLimitCondition, never> name="limitConditions">
        {({ fields: limitConditions }) => {
          batch(() =>
            limitConditions.forEach((name, idx) => {
              const {
                max,
                min,
                units: currentUnits,
              } = values.limitConditions[idx] ?? {};

              // Change units to match the selected column if it isn't a related unit (because the column changed for example)
              if (
                !currentUnits ||
                (units && !MathUnit.areRelated(currentUnits, units))
              ) {
                change(`${name}.units`, units);
              }

              // Clear the min value if the limit type changed to max
              if (limitType === LimitType.max && !!min) {
                change(`${name}.min`, undefined);
              }

              // Clear the max value if the limit type changed to min
              if (limitType === LimitType.min && !!max) {
                change(`${name}.max`, undefined);
              }
            }),
          );
          return (
            <Container $withMaxWidth={!hasLimitFilterColumns}>
              {limitConditions.map(
                (limitConditionName, limitConditionIndex) => (
                  <Row key={limitConditionName}>
                    <LimitCondition
                      hideLabels={limitConditionIndex > 0}
                      limitType={limitType || LimitType.max}
                      name={limitConditionName}
                      onDelete={
                        limitConditions.value.length > 1
                          ? () => limitConditions.remove(limitConditionIndex)
                          : undefined
                      }
                      units={units}
                    />
                  </Row>
                ),
              )}
              {hasLimitFilterColumns && (
                <AddButton
                  color="primary"
                  onClick={() =>
                    limitConditions.push(
                      areViewLimitFiltersEnabled
                        ? getDefaultLimitCondition()
                        : getInitialLimitConditionObsolete(groupedColumns),
                    )
                  }
                  variant="text"
                >
                  <AddIcon />
                  Add another condition
                </AddButton>
              )}
            </Container>
          );
        }}
      </FieldArray>

      {areViewLimitFiltersEnabled && (
        <LimitFilterPicker
          limitFilterColumnNames={limitFilterColumnNames}
          onLimitFilterChecked={handleLimitFilterChange}
        />
      )}
    </>
  );
}
