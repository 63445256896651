import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiTextField from '@material-ui/core/TextField';
import MuiAutocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import React, { FocusEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { bodyMedium } from '../styles/text';
import { SelectableOption } from '../types/SelectableOption';
import { ConditionalWrapper } from './ConditionalWrapper';
import { ErrorText } from './styled/form';
import { VisuallyHidden } from './VisuallyHidden';

const UnderlineTextInput = styled(MuiTextField)`
  & label.Mui-focused {
    color: ${({ theme }) => theme.colors.gray999};
  }
`;

const FormTextInput = styled(MuiTextField)`
  & .MuiInput-root {
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray333};
    border: 1px solid ${({ theme }) => theme.colors.grayddd};
    border-radius: 4px;

    &:hover,
    &.Mui-focused {
      outline: ${({ theme }) => `1px solid ${theme.colors.gray333}`};
    }

    &.Mui-error {
      border-color: ${({ theme }) => theme.colors.darkRed};
    }
  }
`;

const InputLabel = styled(MuiInputLabel)`
  ${bodyMedium}
  position: static;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.colors.gray333};
  transform: none;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.gray333};
  }

  &.Mui-error {
    color: ${({ theme }) => theme.colors.darkRed};
  }
`;

type ComboboxProps = Omit<
  React.ComponentProps<typeof MuiAutocomplete>,
  | 'defaultValue'
  | 'freeSolo'
  | 'loading'
  | 'loadingText'
  | 'multiple'
  | 'onChange'
  | 'options'
  | 'renderInput'
  | 'value'
> & {
  error?: boolean;
  /**
   * Only displayed when variant === 'form'
   */
  errorText?: string;
  isLabelHidden?: boolean;
  isLoading?: boolean;
  isLoadingError?: boolean;
  label: string;
  loadingErrorText?: string;
  loadingText?: string;
  name?: string;
  /**
   * Aligns with the signature of native html change event handler
   */
  onChange: (event: {
    target: {
      value?: string;
    };
  }) => void;
  options: SelectableOption[];
  placeholder?: string;
  required?: boolean;
  textFieldProps?: React.ComponentProps<typeof MuiTextField>;
  value?: string;
  variant?: 'form' | 'underline';
};

export function Combobox({
  error,
  errorText,
  isLabelHidden = false,
  isLoading = false,
  isLoadingError = false,
  label,
  loadingErrorText = `Couldn't load select options`,
  loadingText = 'Loading...',
  name = 'combobox-control',
  onChange: onChangeProp,
  onFocus,
  openOnFocus = true,
  options,
  placeholder,
  required,
  textFieldProps,
  value,
  variant = 'form',
  ...props
}: ComboboxProps) {
  const [idRandomify] = useState(uuidv4);
  const valueAsSelectableOption = useMemo(
    () => options.find((opt) => opt?.value === value) || null,
    [options, value],
  );

  const [inputValue, setInputValue] = useState(
    valueAsSelectableOption?.label || '',
  );

  const filterOptions = useCallback(
    (allOptions: SelectableOption[], state) =>
      allOptions.filter((opt) =>
        opt.label.toLowerCase().includes(state.inputValue?.toLowerCase()),
      ),
    [],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      const errorTextId = `${name}-error-text-${idRandomify}`;
      const inputLabelId = `${name}-input-label-${idRandomify}`;
      /* underline variant can't currently have a hidden label */
      return variant === 'underline' ? (
        <UnderlineTextInput
          {...params}
          {...textFieldProps}
          label={label}
          InputLabelProps={{ shrink: true }}
          required={required}
        />
      ) : (
        <>
          <ConditionalWrapper
            condition={isLabelHidden}
            /* eslint-disable-next-line react/no-unstable-nested-components */
            wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
          >
            <InputLabel id={inputLabelId} error={error} required={required}>
              {label}
            </InputLabel>
          </ConditionalWrapper>
          <FormTextInput
            {...params}
            {...textFieldProps}
            variant="standard"
            error={error}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              disableUnderline: true,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...textFieldProps?.inputProps,
              ...params.inputProps,
              placeholder,
              required,
              'aria-labelledby': inputLabelId,
              'aria-describedby': errorTextId,
            }}
          />
          <ErrorText id={errorTextId} error={error}>
            {errorText || ' '}
          </ErrorText>
        </>
      );
    },
    [
      name,
      idRandomify,
      variant,
      textFieldProps,
      label,
      placeholder,
      required,
      isLabelHidden,
      error,
      errorText,
    ],
  );

  return (
    /* this component will only ever allow a single selection, and the selected value is required to be one of the options provided */
    <MuiAutocomplete<SelectableOption, false, boolean, false>
      {...props}
      value={valueAsSelectableOption}
      onChange={(event, option) => {
        onChangeProp({ target: { value: option?.value } });
      }}
      onFocus={(event: FocusEvent<HTMLInputElement>) => {
        // setTimeout based on this: https://stackoverflow.com/a/54229871
        const { target } = event;
        setTimeout(() => target.select(), 0);
        if (onFocus) {
          onFocus(event);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      // only show an 'x' icon to clear if the field is not required
      {...(required ? { closeIcon: null } : [])}
      filterOptions={filterOptions}
      options={options}
      openOnFocus={openOnFocus}
      getOptionSelected={(option, selectedOption) =>
        option.value === selectedOption.value
      }
      getOptionLabel={(option) => option.label}
      renderInput={renderInput}
      loading={isLoading || isLoadingError}
      loadingText={
        (isLoading && loadingText) || (isLoadingError && loadingErrorText)
      }
    />
  );
}
