import { isLimitThresholdColumn } from '@monorepo/logs/src/components/Views/ViewResultsTable/resultsMapper';
import { useQueryStepsForm } from '@monorepo/logs/src/contexts/QueryStepsContext';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { SelectField } from '@monorepo/shared/componentsV2/fields/SelectField';
import { bodyRegular } from '@monorepo/shared/styles/text';
import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { isRequired } from '@monorepo/shared/utils/validators';
import AddIcon from '@svg/add.svg';
import arrayMutators from 'final-form-arrays';
import {
  ColumnType,
  Conjunction,
  FilterStep,
  MathUnit,
  QueryColumn,
  QueryOperationType,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { DeepPartial } from 'utility-types';
import { useSingleQueryStep } from '../../../contexts/SingleQueryStepContext';
import { InvalidQueryStep } from '../InvalidQueryStep';
import { FilterCondition } from './FilterCondition';
import {
  getFilterConditionFormValues,
  getFilterConditionForRequest,
} from './FilterCondition/FilterConditionFactory';
import { FormValues, isSingleFilter } from './types';

const Container = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 4rem repeat(3, 1fr) 2rem;
  column-gap: 1rem;
  align-items: start;
  justify-items: center;
  width: 100%;
`;

const BodyRegularText = styled.div`
  ${bodyRegular}
  margin-top: .5rem;
`;

const AddButton = styled(Button)`
  width: fit-content;
`;

const COLUMN_TYPES_ALLOWED_IN_FILTERS = [
  ColumnType.BOOLEAN,
  ColumnType.DATETIME,
  ColumnType.DATE_RANGE,
  ColumnType.NUMBER,
  ColumnType.TEXT,
];

const conjunctionOptions: SelectableOption<Conjunction>[] = [
  { label: 'and', value: Conjunction.AND },
  { label: 'or', value: Conjunction.OR },
];

const getConjunctionComponent = (
  conditionIndex: number,
  conjunction: Conjunction | undefined,
) => {
  if (conditionIndex === 0) {
    return <BodyRegularText>Where</BodyRegularText>;
  }
  if (conditionIndex === 1) {
    return (
      <SelectField
        isLabelHidden
        label="Conjunction"
        name="conjunction"
        options={conjunctionOptions}
        required
        validate={isRequired}
      />
    );
  }
  return <BodyRegularText>{conjunction}</BodyRegularText>;
};

const buildQueryStep = (
  queryStep: FilterStep,
  values: FormValues,
  availableColumns: QueryColumn[],
): FilterStep => ({
  ...queryStep,
  operationType: QueryOperationType.FILTER,
  operation: {
    conjunction: values.conjunction || Conjunction.AND,
    filters: values.filters.map((filter) => {
      // Currently only SingleFilter is allowed. This is for type narrowing only.
      if (!!filter && !isSingleFilter(filter)) {
        throw new Error('Invalid filter');
      }

      return getFilterConditionForRequest({
        columns: availableColumns,
        filterConditionFromForm: filter,
      });
    }),
  },
});

const buildFormValues = (
  queryStep: FilterStep | undefined,
  availableColumns: QueryColumn[],
): DeepPartial<FormValues> => {
  if (!queryStep || !queryStep.operation) {
    return {
      conjunction: Conjunction.AND,
      filters: [{}],
    };
  }

  const filters = queryStep.operation.filters?.map((filter) => {
    // Currently only SingleFilter is allowed. This is for type narrowing only.
    if (!!filter && !isSingleFilter(filter)) {
      throw new Error('Invalid filter');
    }
    return getFilterConditionFormValues({
      columns: availableColumns,
      filterConditionFromQueryStep: filter,
    });
  });

  return { conjunction: queryStep.operation.conjunction, filters };
};

export function FilterQueryStep() {
  const { queryStep, availableColumns, index, limitColumnSets } =
    useSingleQueryStep();
  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = useQueryStepsForm();
  const filterQueryStep = queryStep as FilterStep;

  const handleOnSubmit = useCallback(
    (values: FormValues) =>
      onQueryStepSubmit(
        buildQueryStep(filterQueryStep, values, availableColumns),
        index,
      ),
    [availableColumns, filterQueryStep, index, onQueryStepSubmit],
  );

  const columnOptions = useMemo(
    () =>
      availableColumns
        .filter(
          (c) =>
            COLUMN_TYPES_ALLOWED_IN_FILTERS.includes(c.columnType) &&
            !c.isHidden &&
            !isLimitThresholdColumn(c.columnName, limitColumnSets),
        )
        .map((column) => ({
          label: `${column.columnLabel}${
            column.units ? ` (${MathUnit.getLabel(column.units)})` : ''
          }`,
          value: column.columnName,
        }))
        .sort((op1, op2) => op1.label.localeCompare(op2.label)),
    [availableColumns, limitColumnSets],
  );

  const initialValue = useMemo(
    () => buildFormValues(filterQueryStep, availableColumns),
    [availableColumns, filterQueryStep],
  );

  if (columnOptions.length === 0) {
    return (
      <InvalidQueryStep errorMessage="There are no fields to filter on." />
    );
  }

  return (
    <Form<FormValues, DeepPartial<FormValues>>
      initialValues={initialValue}
      mutators={{ ...arrayMutators }}
      onSubmit={handleOnSubmit}
      subscription={{ pristine: true }}
    >
      {({ form: { getFieldState }, handleSubmit, pristine }) => {
        registerHandleSubmitForStep(handleSubmit, index);
        setQueryStepsArePristine(pristine);
        return (
          <FieldArray name="filters">
            {({ fields: formFields }) => (
              <Container>
                <Grid>
                  {formFields.map((formFieldName, formFieldIndex) => (
                    <FilterCondition
                      columnOptions={columnOptions}
                      conjunctionComponent={getConjunctionComponent(
                        formFieldIndex,
                        getFieldState('conjunction')?.value,
                      )}
                      key={formFieldName}
                      name={formFieldName}
                      onDelete={
                        formFields.value.length > 1
                          ? () => formFields.remove(formFieldIndex)
                          : undefined
                      }
                    />
                  ))}
                </Grid>
                <AddButton
                  color="primary"
                  onClick={() => formFields.push({})}
                  variant="text"
                >
                  <AddIcon />
                  Add a condition
                </AddButton>
              </Container>
            )}
          </FieldArray>
        );
      }}
    </Form>
  );
}
